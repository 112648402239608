import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery} from 'gatsby';

import Hero from '@components/en/common/hero';
import ContactIntro from '@components/en/common/contact-intro';

import Message_hero__mds from '@img/svg/message/hero__mds.svg';
import Layout from '@components/common/layout';
import { Container, Cont, Column, Prof, Img } from '@components/message/styles';
import { Tab } from '@components/common/styles';
import { TabItem } from '@components/en/common/styles';
import { color, mixin } from '@src/settings';

const Message_page = ({ data }) => (
	<Layout baseBgColor={ color.paleGray }>
		<Hero
			img={ data["Message_hero_img"] }
			title={<Message_hero__mds />}
		/>
		<Tab>
			<TabItem to="/en/message" className="isActive"><span>Message</span></TabItem>
			<TabItem to="/en/company"><span>Company</span></TabItem>
		</Tab>
		<Container>
			<Cont>
				<Prof>
					<Img fluid={ data["president"].childImageSharp.fluid } fade={ true } />

					<div>
						<h2>Managing Director</h2>
						<p>Dato’ Stan Lee</p>
					</div>

				</Prof>
				<Column>
					<h2>IT IS ALL ABOUT THE PEOPLE</h2>

					<p>Since our establishment in year 2000, we constantly reflect on our humble beginnings and we are pleased to see many changes in our company. Amidst all the changes, few things never changed. For one, we still believe in nurturing our people.</p>

					<p>In our pursuit for better quality products, we always start from the passion within our people. Their passion is the drive to produce better products year after year. This ultimately results in satisfaction from our clients and end customers, and strengthen their trust in us.</p>
<p>We will continue to grow the company to the next level and to do that,  it is all about the people. Our people shall be grown not only in terms of technical skills and management, but also spiritually with the company core values, like treating each other with respect and integrity. Our culture and our values  will permeate not only within the organisation but also in the lives of all our associates.<br /><br />We always appreciate your continuous support and guidance to us.</p> 
				</Column>
			</Cont>
		</Container>
		<ContactIntro />
	</Layout>
)


export const query= graphql`
	query messageImgs_en{

		Message_hero_img: file( relativePath: { eq: "message/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		president: file( relativePath: { eq: "message/president.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`

export default Message_page

